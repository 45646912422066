export const arabicTitles = {
  classes: "الاقسام",
  teachers: "الاساتذة",
  students: "التلاميذ",
  parents: "أولياء الأمور",
  courses: "المواد",
  CGT: "اسناد المواد",
  periods: "التوزيع السنوي",
  "/": " جدول الحصص",
  credentials: "تسجيل الدخول",
  assessments: "وحدات المواد",
  "quarterly-grade": "الدورات",
  "trimester-assessment": "الفروض",
  schedule: "استعمال الزمن",
  marks: " النقط",
  homework: " الواجبات المنزلية",
  "create-timetable": "إستعمال الزمن",
};
